<template>
  <div class="array-question">
    <h4
      :class="[
        body.header ? 'mb-3' : '',
        heading ? 'question-title h2' : 'label-text',
      ]"
    >
      {{ body.question }}
    </h4>
    <p class="sub-text">{{ body.header }}</p>
    <b-form-tags
      add-on-change
      input-id="tags-basic"
      v-model="answer"
      class="text-tag mb-3"
      :class="{ 'fs-mask': this.isSensitive }"
      tag-variant="info"
      add-button-variant="info"
      separator=","
      :placeholder="placeholder"
      @focus.native="focus"
      :disabled="disabled"
    />
    <b-button
      pill
      block
      variant="info"
      @click="checkAnswer"
      :disabled="disabled"
      >Continue</b-button
    >
  </div>
</template>

<script>
export default {
  props: {
    body: {
      type: Object,
    },
    composite: {
      default: false,
      type: Boolean,
    },
    othersField: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    heading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isSensitive() {
      return this.body.hasOwnProperty("sensitive") && this.body.sensitive;
    },
  },
  data() {
    return {
      placeholder: "Enter here...",
      answer: [],
    };
  },
  methods: {
    focus() {
      event.target.querySelector("#tags-basic").focus();
    },
    checkAnswer() {
      if (
        this.answer.length == 0 &&
        (!this.body.hasOwnProperty("allowempty") || !this.body.allowempty)
      ) {
        this.$emit("alert", "Please fill in all fields.");
      } else {
        this.$emit("submitAnswer", this.body.id, this.answer, this.body);
      }
    },
  },
  mounted() {
    if (this.body.hasOwnProperty("answer")) {
      this.answer = this.body.answer;
    } else if (this.othersField.length > 0) {
      this.answer = this.othersField;
    } else {
      this.answer = [];
    }

    if (this.body.hasOwnProperty("placeholder")) {
      this.placeholder = this.body.placeholder;
    }
  },
};
</script>

<style scoped>
/deep/.b-form-tags-button {
  min-width: initial;
}

/deep/.b-form-tag-content {
  font-size: 1.35em;
}
</style>

<style lang="scss" scoped>
.text-tag {
  background-color: #f8f8f8;

  &:focus {
    background-color: #f8f8f8;
  }
}

.heading {
  font-weight: normal !important;
}
</style>
