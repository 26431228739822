var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "array-question" },
    [
      _c(
        "h4",
        {
          class: [
            _vm.body.header ? "mb-3" : "",
            _vm.heading ? "question-title h2" : "label-text",
          ],
        },
        [_vm._v(" " + _vm._s(_vm.body.question) + " ")]
      ),
      _c("p", { staticClass: "sub-text" }, [_vm._v(_vm._s(_vm.body.header))]),
      _c("b-form-tags", {
        staticClass: "text-tag mb-3",
        class: { "fs-mask": this.isSensitive },
        attrs: {
          "add-on-change": "",
          "input-id": "tags-basic",
          "tag-variant": "info",
          "add-button-variant": "info",
          separator: ",",
          placeholder: _vm.placeholder,
          disabled: _vm.disabled,
        },
        nativeOn: {
          focus: function ($event) {
            return _vm.focus.apply(null, arguments)
          },
        },
        model: {
          value: _vm.answer,
          callback: function ($$v) {
            _vm.answer = $$v
          },
          expression: "answer",
        },
      }),
      _c(
        "b-button",
        {
          attrs: {
            pill: "",
            block: "",
            variant: "info",
            disabled: _vm.disabled,
          },
          on: { click: _vm.checkAnswer },
        },
        [_vm._v("Continue")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }